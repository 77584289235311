import { CheckBoxRounded, Edit, ExpandMore, Label, SendOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Snackbar, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import XMLViewer from 'react-xml-viewer';
import { useRecoilState, useRecoilValue } from "recoil";

import { t } from "i18next";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_IT } from "material-react-table/locales/it";
import { getECNFilling, sendEcn, setECNFilling } from "../../client/apiClient";
import { selectedMailAtom, wizardPageAtom } from "../../states/wizardState";
import BackdropLoading from "../utils/BackdropLoading";
import EditEcnDialog from "./editEcnDialog";
import { useNavigate } from 'react-router-dom';

const SendEcnPage = () => {
    const selectedMail = useRecoilValue(selectedMailAtom);

    // eslint-disable-next-line
    const [wizardPage, setWizardPage] = useRecoilState(wizardPageAtom);

    const [snackbarStatus, setSnackBarStatus] = useState(false);
    const [snackbarColor, setSnackBarColor] = useState();
    const [snackbarMessage, setSnackBarMessage] = useState("");

    const [open, setOpen] = useState(false);
    const [sendok,setSendOk] = useState(false);

    const [loading, setLoading] = useState(false);
    const [msgStatusSend,setMsgStatusSend] = useState("");
    const [xml, setXml] = useState();
    const [xmlError, setXmlError] = useState();

    const [allFields, setAllFields] = useState([]);
    const [importantFields, setImportantFields] = useState([]);
    
    const [fieldData, setFieldData] = useState();
    const [showAllFields, setShowAllFields] = useState(false);

    const navigate = useNavigate();

    const openDialog = () => {
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
        if(sendok)
            setWizardPage({ id: 0, name: "Scelta Range Date" })
    };

    const sendVerify = async () => {
        try {
            setMsgStatusSend("Avvio verifica ECN...")
            setLoading(true);
            setSendOk(false);
            
            let res = await sendEcn(selectedMail.id_mail,"04");
            if (res && res.data && res.data.requestXML) {
                
                let xml = res.data.responseSoap.data;
                setXml(xml);

                if (xml.includes("<SAP:Error")) {
                    setXmlError(xml.substring(xml.indexOf("<SAP:Stack>") + 11, xml.indexOf("</SAP:Stack>")));
                    setMsgStatusSend("");
                    setLoading(false);
                    openDialog();
                }
                else if (xml.includes("<ZBAPIRET2>") && !xml.includes("<TYPE>I</TYPE>")) {
                    setXmlError(xml.substring(xml.indexOf("<MESSAGE>") + 9, xml.indexOf("</MESSAGE>")));
                    setMsgStatusSend("");
                    setLoading(false);
                    openDialog();
                    
                } else {
                    setXmlError();
                    send();
                }

            } else {
                //APERTURA SNACKBAR
                setSnackBarStatus(true)
                setSnackBarColor("red")
                setSnackBarMessage("Errore durante l'invio ECN!")
                setMsgStatusSend("");
                setLoading(false);
            }

        } catch (e) {
            alert(e);
            setLoading(false);
        }
    }

    const send = async () => {
        try {
            //setLoading(true);
            setMsgStatusSend("Verifica completata con successo. Invio ECN...");
            let res = await sendEcn(selectedMail.id_mail,"01");
            if (res && res.data && res.data.requestXML) {
                openDialog();
                let xml = res.data.responseSoap.data;
                //setXml(xml);

                if (xml.includes("<SAP:Error")) {
                    setSendOk(false);
                    setXmlError(xml.substring(xml.indexOf("<SAP:Stack>") + 11, xml.indexOf("</SAP:Stack>")));
                }
                else if (xml.includes("<ZBAPIRET2>") && !xml.includes("<TYPE>I</TYPE>")) {
                    setSendOk(false);
                    setXmlError(xml.substring(xml.indexOf("<MESSAGE>") + 9, xml.indexOf("</MESSAGE>")));
                } else {
                    setSendOk(true);
                    setXmlError();
                }

            } else {
                if (res && res.data && res.data.responseSoap && res.data.responseSoap.data && res.data.responseSoap.data.message)
                {
                    let xml = res.data.responseSoap.data.message;
                    setXml(xml);
                    setSendOk(false);
                    setXmlError(xml.substring(xml.indexOf("<faultstring>") + 13, xml.indexOf("</faultstring>")));
                    openDialog();
                }
                else
                {
                //APERTURA SNACKBAR
                 setSendOk(false);
                 setSnackBarStatus(true)
                 setSnackBarColor("red");
                 setSnackBarMessage("Errore durante l'invio ECN!")
                }
            }
            setMsgStatusSend("");
            setLoading(false);
        } catch (e) {
            alert(e);
            setSendOk(false);
            setLoading(false);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarStatus(false);

    };

    const getEcnData = () => {
        getECNFilling(selectedMail.id_mail).then(res => {
            setAllFields(res.data);

            //nei fields inserisco quelli importanti
            let fields = [];
            res.data.forEach(dt => {
                if(dt.FieldMandatory || (dt.FieldCim.Name !== "" && dt.FieldRds.Name !== "")){
                    fields.push(dt);
                }
            })
            setImportantFields(fields);

        }).catch(() => { });
    }

    useEffect(() => {
        getEcnData();
    }, []);

    const updateEcn = (field) => {
        setECNFilling(selectedMail.id_mail, field).then(res => {
            setFieldData();
            getEcnData();

            //APERTURA SNACKBAR
            setSnackBarStatus(true);
            setSnackBarColor("green");
            setSnackBarMessage("Modifica Effettuata");

        }).catch(() => { });
    }

    function getColumns() {
        return [
            {
                header: 'Campo ECN',
                accessorKey: 'FieldEcn',
                Cell: ({ cell }) => 
                    {
                    const rowData = cell.row.original;

                    return <Box sx={{ whiteSpace: "normal", wordWrap: 'break-word', display:"flex", flexDirection:"column" }}>
                        {<b>{cell.getValue()}</b>}
                        {rowData.FieldCim.Name !== "" ? <span>CAMPO CIM: {t("cim."+rowData.FieldCim.Name)}</span> : ""}
                        {rowData.FieldRds.Name !== "" ? <span>CAMPO RDS: {t("rds.table."+rowData.FieldRds.Name)}</span> : ""}
                    </Box>
                    }
                
            },
            {
                header: 'RDS',
                accessorKey: 'FieldRds',
                Cell: ({ cell }) => {

                    const rowData = cell.row.original;

                    let differentCim = false;
                    if (rowData.FieldRds.Name && rowData.FieldCim.Name) {
                        if (rowData.FieldRds.Value !== rowData.FieldCim.Value) {
                            differentCim = true;
                        }
                    }


                    return <Box
                        sx={{
                            border: differentCim ? "2px solid orange" : "",
                            width: "100%",
                            display: 'flex',
                            justifyContent: 'space-between',
                            whiteSpace: "normal",
                            wordWrap: 'break-word'
                        }}
                    >
                        <p>{cell.getValue()["Value"] ? cell.getValue()["Value"] : "-"}</p>
                        {rowData.FieldRds.Name &&
                            <IconButton
                                color="warning"
                                size="small"
                                onClick={() => {
                                    setFieldData({
                                        "type": "rds",
                                        "name": rowData.FieldRds.Name,
                                        "value": rowData.FieldRds.Value
                                    })
                                }}
                            >
                                <Edit />
                            </IconButton>}
                    </Box>
                }
            },
            {
                header: 'CIM',
                accessorKey: 'FieldCim',
                Cell: ({ cell }) => {

                    const rowData = cell.row.original;
                    let differentRds = false;
                    if (rowData.FieldRds.Name && rowData.FieldCim.Name) {
                        if (rowData.FieldRds.Value !== rowData.FieldCim.Value) {
                            differentRds = true;
                        }
                    }

                    return <Box
                        sx={{
                            border: differentRds ? "2px solid orange" : "",
                            width: "100%",
                            display: 'flex',
                            justifyContent: 'space-between',
                            whiteSpace: "normal",
                            wordWrap: 'break-word'
                        }}
                    >
                        <p>{cell.getValue()["Value"] ? cell.getValue()["Value"] : "-"}</p>
                        {rowData.FieldCim.Name &&
                            <IconButton
                                color="warning"
                                size="small"
                                onClick={() => {
                                    setFieldData({
                                        "type": "cim",
                                        "name": rowData.FieldCim.Name,
                                        "value": rowData.FieldCim.Value
                                    })
                                }}
                            >
                                <Edit />
                            </IconButton>
                        }
                    </Box>
                }
            },
            {
                header: 'Descrizione',
                accessorKey: 'Description',
                Cell: ({ cell }) => (
                    <Box sx={{ whiteSpace: "normal", wordWrap: 'break-word' }}>{cell.getValue()}</Box>
                )
            },
            {
                header: 'Obbligatorio',
                accessorKey: 'FieldMandatory',
                size: 80,
                Cell: ({ cell }) => (
                    <Box sx={{ width: "100%", textAlign: "center" }}>{cell.getValue() ? <CheckBoxRounded color="info" /> : null}</Box>
                )
            }
        ]
    }

    return loading ? <BackdropLoading message={msgStatusSend} /> : (
        <>
            <Box sx={{ m: 1 }}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={snackbarStatus}
                    autoHideDuration={3000}
                    key={"modifySuccess"}
                    onClose={handleClose}
                    message={snackbarMessage}
                    sx={{
                        '& .MuiSnackbarContent-root': {
                            backgroundColor: snackbarColor,
                            color: 'white', // Colore del testo
                        },
                    }}
                />

                <Dialog
                    fullScreen={false}
                    maxWidth={"xl"}
                    open={open}
                    onClose={closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" sx={{ color: "white", background: xmlError ? "red" : "green" }}>
                        {"Invio ECN " + (xmlError ? "-> ERRORE" : "OK")}
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ height: "70vh", m: 1 }}>
                            {xmlError && <Alert severity="error" sx={{ m: 3 }}>{xmlError}</Alert>}

                            <Accordion>
                                <AccordionSummary
                                    defaultExpanded={xmlError ? false : true}
                                    expandIcon={<ExpandMore />}
                                >
                                    XML di Risposta
                                </AccordionSummary>
                                <AccordionDetails>
                                    <XMLViewer xml={xml} />

                                </AccordionDetails>
                            </Accordion>

                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { closeDialog(); /*setWizardPage({ id: 0, name: "Scelta Range Date" })*/ }}>OK</Button>
                    </DialogActions>
                </Dialog>


                <EditEcnDialog field={fieldData} open={fieldData != null} onUpdate={(field) => { updateEcn(field) }} />

                <Box sx={{ mt: 1, height: '68vh' }}>

                    <Box sx={{ textAlign: "right", mb: 1 }}>
                        <FormControlLabel control={<Checkbox color="info" onChange={(e) => { setShowAllFields(!showAllFields) }} />} label="Mostra Tutti" />
                    </Box>
                    
                    <MaterialReactTable
                        muiTableBodyCellProps={{ sx: { borderRight: '1px solid #eee' } }}
                        localization={MRT_Localization_IT}
                        initialState={{
                            density: "compact",
                            columnPinning: {
                                right: ['mrt-row-actions'],
                            },
                        }}
                        enableColumnResizing
                        layoutMode="grid"
                        columns={getColumns()}
                        data={showAllFields ? allFields : importantFields}
                        enablePagination={false}
                        muiTableContainerProps={{
                            sx: {
                                height: '91%',
                                overflow: 'auto',
                            },
                        }}
                        muiTablePaperProps={{
                            sx: {
                                height: '100%',
                            },
                        }}
                    />
                </Box>

                <Box sx={{ textAlign: "right" }}>
                    <Tooltip title={t("tooltips.invioEcn")}>
                        <Button color="info" variant="contained" startIcon={<SendOutlined />} sx={{ mb: 2, mt: 9, mr: 1 }} onClick={sendVerify}>Invio ECN</Button>
                        
                    </Tooltip>
                </Box>
            </Box>
        </>
    )
}

export default SendEcnPage;