import axios from 'axios';
import dayjs from 'dayjs';

import { setRecoil } from 'recoil-nexus';
import { Config } from "../configApp";
import { errorMsg } from '../states/wizardState';

let _axClient = null;


const axiosClient = () => {
    if (_axClient != null)
        return _axClient;

    _axClient = axios.create({
        baseURL: Config.serviceUrls.API_BASE_PATH,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    });

    _axClient.interceptors.request.use(function (config) {

        if (localStorage.getItem("recoil-persist")) {
            let data = JSON.parse(localStorage.getItem("recoil-persist"));
            if(data.accessTokenAtom)
              config.headers.Authorization = data.accessTokenAtom;
            if(data.userAccountAtom && data.userAccountAtom.username)
            {
            let vals= data.userAccountAtom.username!==undefined && data.userAccountAtom.username!=null ?data.userAccountAtom.username.split("@"):[];
            if(vals.length > 0)
              config.headers.username = vals[0];
            else
              config.headers.username="";
            }
            else
            config.headers.username=data.userAtom
        }

        return config;
    });

    _axClient.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.data) {
                if (error.response.data.errore)
                    setRecoil(errorMsg, error.response.data.errore);
                else if (error.response.data.message)
                    setRecoil(errorMsg, error.response.data.message);
            }
            else {
                setRecoil(errorMsg, error.message);
            }

            console.error(error);

            return Promise.reject(error);
        });

    return _axClient;
};

const searchEmail = (fromDate, toDate, searchText, exactText) => {
    return axiosClient().post(`mails_service/search`, {
        "from_date": dayjs(fromDate).format("YYYY-MM-DD"),
        "to_date": dayjs(toDate).format("YYYY-MM-DD"),
        "txt_search": !exactText ? searchText : "",
        "txt_search_exact": exactText
    });
}

const emailDetail = (mailId,type) => {
    return axiosClient().get(`mails_service/detail/${mailId}/${type}`);
}

const emailPreview = (mailId, name_attach) => {
    return axiosClient().get(`mails_service/preview_attach/${mailId}?name=${name_attach}`);
}

const ack = () => {
    return axiosClient().get(`mails_service/ack`);
}

const ackEmail = (mailId) => {
    return axiosClient().get(`mails_service/ack/${mailId}`);
}

const startProcessEmail = (mailId) => {
    return axiosClient().post(`mails_service/start_process`, {
        "id": mailId
    });
}

const cancelProcessEmail = (mailId, reason) => {
    return axiosClient().post(`mails_service/cancel_process/${mailId}`, {
        "reason": reason
    });
}

const setOperatorMail = (mailId) => {
    return axiosClient().post(`mails_service/set_operator/${mailId}`, {});
}

const getProcessMail = (mailId, step) => {
    return axiosClient().get(`mails_service/process_mail_detail/${mailId}/${step}`);
}

const releaseProcessMail = (mailId) => {
    return axiosClient().post(`mails_service/clear_operator/${mailId}`, {});
}

const attachDownload = (mailId, original_name) => {
    return axiosClient().get(`mails_service/download_attach/${mailId}/${original_name}`, { responseType: "blob" });
}

const ecnResponseDownload = (mailId) => {
    return axiosClient().get(`mails_service/download_enc_response/${mailId}`, { responseType: "blob" });
}

const adjustCimDetailAttach = (id, input) => {
    return axiosClient().post(`mails_service/adjust_cim_detail_attach/${id}`, input);
}

const runReportSummary = (mailId) => {
    let input = {
        "id_mail": mailId
    }
    return axiosClient().post(`/mails_service/runReportSummary`, input);
}

const selectRds = (update) => {
    return axiosClient().put(`mails_service/selectRDS`, update);
}

const searchRID = (input) => {
    return axiosClient().post(`mails_service/searchRID`, input);
}

const confirmRID = (update) => {
    return axiosClient().put(`mails_service/confirmRID`, update);
}

const getECNFilling = (mailId) => {
    return axiosClient().get(`mails_service/verifyECNFilling/${mailId}`);
}

const setECNFilling = (mailId, update) => {
    return axiosClient().put(`mails_service/modifyECNFilling/${mailId}`, update);
}

/* */
const getListProcess = () => {
    return axiosClient().get('api/Process/getListProcess');
}

const updateProcess = (update) => {
    return axiosClient().put(`api/Process/updateProcess?forceUpdateAllfields=true`, update);
}

const getDownloadXML = (mailId) => {
    let input = {
        "id_mail": mailId,
        "code": "04"
    }
    return axiosClient().post(`/api/H2H/H2HCIMECNMessages`, input);
}

const getDownloadExcel = (mailId) => {
    let input = {
        "id_mail": mailId,
    }
    const config = { responseType: 'blob' };
    return axiosClient().post(`/api/Excel/ExportDistintaToExcel`, input, config);
}

const sendEcn = (mailId,code) => {
    let input = {
        "id_mail": mailId,
        "code": code,
        "send": true
    }
    return axiosClient().post(`/api/H2H/H2HCIMECNMessages`, input);
}

const startProcessIACim = async (mailId) => {
    let input = {
        "type_elab": "cim",
        "id_mail": mailId
    }
    return axiosClient().post(`mails_service/startProcessIA`, input);
}

const startProcessOrfeus = async (mailId) => {
    let input = {
        "id_mail": mailId
    }
    return axiosClient().post(`mails_service/restartOrfeus`, input);
}

const startProcessCI = async (mailId) => {
    let input = {
        "id_mail": mailId
    }
    return axiosClient().post(`mails_service/runDecodeCodes`, input);
}

const startProcessRds = async (mailId, fromDay, toDay) => {
    let input = {
        "id_mail": mailId,
        "from_days": "" + fromDay,
        "to_days": "" + toDay
    }
    return axiosClient().post(`mails_service/runRDS`, input);
}

const startProcessIADetail = async (mailId) => {
    let input = {
        "type_elab": "distinta",
        "id_mail": mailId
    }
    return axiosClient().post(`mails_service/startProcessIA`, input);
}

const startProcessIA = async (mailId) => {
    let input = {
        "type_elab": "all",
        "id_mail": mailId
    }
    return axiosClient().post(`mails_service/startProcessIA`, input);
}

const goToSend = async (mailId) => {
    let input = {
        "id_mail": mailId
    }
    return axiosClient().post(`mails_service/goToSend`, input);
}

const getLogs = async (input) => {
    return axiosClient().post(`/api/Log/search`, input);
}

export {
    ack, ackEmail, adjustCimDetailAttach, attachDownload,ecnResponseDownload, cancelProcessEmail, confirmRID, emailDetail, emailPreview, getDownloadExcel, getDownloadXML, getECNFilling, getListProcess, getLogs, getProcessMail, goToSend,
    releaseProcessMail, runReportSummary, searchEmail, searchRID, selectRds, sendEcn, setECNFilling, setOperatorMail, startProcessCI, startProcessEmail, startProcessIA, startProcessIACim, startProcessIADetail, startProcessOrfeus, startProcessRds,
    updateProcess
};

